<template>
  <ul>
    <li v-for="item of routes" v-bind:key="item.path">
      <router-link :to="item.path">{{ item.name }}</router-link>
    </li>
  </ul>
</template>

<script>
import {mapStores} from 'pinia'
import {useDesignStore} from "@/stores/designStore"

export default {
  name: "Interior",
  mounted() {
    this.designStore.setShowLogo(false);
    this.designStore.definitionHeader(true);
    this.designStore.setTitleOnPage({
      title: 'Page Title',
      sub_title: 'Page Subhead',
      img_class: 'bg-interior',
      btn_history: false,
    });
  },
  computed: {
    ...mapStores(useDesignStore),
  },
}
</script>

<style lang="scss">

</style>